<template>
  <div>
    <div class="my-30 py-40 center">
      <b-spinner :variant="status === 'Success' ? 'success' : 'danger'"></b-spinner>
      <p :class="`mt-5 color-${status === 'Success' ? 'blue' : 'red'}`">{{ status }}</p>
    </div>
  </div>
</template>

<script>
import { getLocalStorageInfo } from '../../../functions';

export default {
  name: 'PaymentConfirm',
  data() {
    return {
      status: '',
    }
  },
  mounted() {
    this.status = this.$route.params.status || 'unknown';
    if (getLocalStorageInfo('eventboxTransactionInfo')) {
      if (this.status === 'Success') {
        this.$toast.success(this.$t('alert_success'));
        this.$router.push(`/my_tickets`);
      } else if (this.status === 'Pending') {
        window.alert(this.$t('alert_wait_for_ticket'));
        this.$router.push(`/my_tickets`);
      } else {
        this.$toast.error(this.status);
        this.$router.push(`/live_events`);
      }
    }
    localStorage.setItem('eventboxTransactionInfo', '');
    localStorage.setItem('eventboxPaymentMethod', '');
  }
};
</script>